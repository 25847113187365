<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useMathGamesStore } from '~/store/mathGames'
import { useCurrentRoleStore } from '~/store/currentRole'

const currentRoleStore = useCurrentRoleStore()
const { currentRole } = storeToRefs(currentRoleStore)
const route = useRoute()

const navigationWithRole = computed(() => {
  return [
    {
      id: 'dashboard',
      label: 'Dashboard',
      icon: 'i-heroicons-adjustments-horizontal',
      to: `/dashboard/${currentRole.value.role}/${currentRole.value.schoolId}`,
    },
    {
      id: 'games',
      label: 'Games',
      icon: 'i-heroicons-puzzle-piece',
      to: '/games',
    },
  ]
})

const navigationWithoutRole = [
  {
    id: 'home',
    label: 'Home',
    icon: 'i-heroicons-home',
    to: '/',
  },
  {
    id: 'games',
    label: 'Games',
    icon: 'i-heroicons-puzzle-piece',
    to: '/games',
  },
]

const getNavigation = () => {
  if (currentRole.value.role) {
    return navigationWithRole.value
  } else {
    return navigationWithoutRole
  }
}
const navigation = ref(navigationWithoutRole)

const gamesStore = useMathGamesStore()
const { resetGames } = gamesStore

const supabase = useSupabaseClient()
const isOpen = ref(false)
const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) {
    useBugsnag().notify(error)
    console.log(error)
  }
  resetGames()
  isOpen.value = false
  navigateTo('/login')
}

const user = useSupabaseUser()

onMounted(() => {
  navigation.value = getNavigation()
  if (
    user.value &&
    !currentRole.value.role &&
    route.path.includes('dashboard')
  ) {
    navigateTo('/dashboard')
  }
})
watch(
  () => currentRole.value,
  () => {
    navigation.value = getNavigation()
  },
)
</script>
<template>
  <div>
    <nav
      class="block grid grid-cols-2 md:grid-cols-3 shadow-md shadow-gray-200 w-full"
    >
      <NuxtLink to="/">
        <img src="/WLearn_Logo_Blue.svg" class="h-12 pt-2 pl-4" />
      </NuxtLink>
      <div class="hidden md:flex justify-center items-center">
        <span
          v-show="user?.user_metadata?.name"
          class="font-semibold text-lg text-primary text-center"
          >Welcome in<br />
          {{ currentRole?.schoolName }}</span
        >
      </div>
      <div class="flex flex-wrap items-center justify-end p-4">
        <span v-show="user?.email" class="no-underline hidden md:block">
          <NuxtLink to="/dashboard"> {{ user?.email }}</NuxtLink>
        </span>
        <NuxtLink v-show="!user?.email" to="/login" class="flex items-center">
          <UIcon name="i-heroicons-user-circle h-6 w-6 ml-4 cursor-pointer" />
        </NuxtLink>
        <UIcon
          name="i-heroicons-bars-3 h-6 w-6 ml-4 cursor-pointer"
          @click="isOpen = true"
        />
      </div>
    </nav>
    <slot />
    <USlideover v-model="isOpen">
      <UCard
        class="flex flex-col flex-1"
        :ui="{
          body: { base: 'flex-1' },
          ring: '',
          divide: 'divide-y divide-gray-100 dark:divide-gray-800',
        }"
      >
        <template #header>
          <ul class="text-blue-700">
            <li
              v-for="(item, index) in navigation"
              :key="`nav-item-${index}`"
              class="mt-4"
            >
              <NuxtLink
                class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white"
                :to="item.to"
                @click="isOpen = false"
              >
                <div class="flex">
                  <UIcon :name="item.icon" class="h-6 w-6 mx-4" />
                  <span class="font-bold text-lg">
                    {{ item.label }}
                  </span>
                </div>
              </NuxtLink>
            </li>
          </ul>
        </template>
        <template #footer>
          <NuxtLink
            v-show="user"
            class="flex flex-col justify-center h-12 py-8 hover:bg-blue-600 hover:text-white cursor-pointer rounded-lg"
            @click="signOut"
          >
            <div class="flex">
              <UIcon
                name="i-heroicons-arrow-right-on-rectangle"
                class="h-6 w-6 mx-4"
              />
              <span class="font-bold text-lg"> Sign out </span>
            </div>
          </NuxtLink>
        </template>
      </UCard>
    </USlideover>
  </div>
</template>
